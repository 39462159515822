import React from "react"
import { InlineWidget } from "react-calendly"

const Calendly = () => {
  return (
    <article>
      <InlineWidget
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: "#d1036f",
          textColor: "4d5055",
        }}
        prefill={{
          email: "You Email",
          firstName: "Your Name",
          lastName: "Your Last Name",
          name: "Your Full Name",
        }}
        styles={{
          height: "1000px",
        }}
        url="https://calendly.com/vtwebdevelopment/15min?month=2021-03&date=2021-03-09"
        utm={{
          utmCampaign: "Spring Sale 2019",
          utmContent: "Shoe and Shirts",
          utmMedium: "Ad",
          utmSource: "Facebook",
          utmTerm: "Spring",
        }}
      />
    </article>
  )
}

export default Calendly
