import React from "react"
import { BsGeoAlt, BsFillEnvelopeFill } from "react-icons/bs"
import { IoCall } from "react-icons/io5"
import Calendly from "./Calendly"

import InnerHero from "./InnerHero"

const Contact = ({ page }) => {
  return (
    <section id="contact">
      <div className="section u-padding-bottom">
        {!page && (
          <>
            <InnerHero>
              <h1 className="heading__main-title heading__main-title--black">
                Let's start making your perfect website
              </h1>
              <h2 className="heading__main-title--sub u-no-effect">
                Need a new website that converts? Book a call today to discuss
                your project needs.
              </h2>
            </InnerHero>
          </>
        )}
      </div>

      <section className="contact section u-padding">
        <div className="row">
          <div className="col-2-medium-of-2">
            <article className="contact__info">
              <h3 className="heading-ordinary u-margin-bottom-small">
                Remote Office
              </h3>
              <p className="contact__info--detail paragraph u-margin-bottom-medium">
                <BsGeoAlt /> Colchester, VT 05446
              </p>

              <h3 className="heading-ordinary u-margin-bottom-small">
                New Business
              </h3>
              <p className="contact__info--detail paragraph">
                <a
                  href="mailto:contact@vtwebdevelopment.com"
                  target="_blank"
                  className="contact__info--detail--icon paragraph"
                >
                  <BsFillEnvelopeFill /> contact@vtwebdevelopment.com
                </a>
              </p>
              <p className="contact__info--detail paragraph u-margin-bottom-small">
                <a
                  href="tel:802.391.9574"
                  target="_blank"
                  className="contact__info--detail--icon"
                >
                  <IoCall /> 802.391.9574
                </a>
              </p>
            </article>

            <Calendly />
          </div>
        </div>
      </section>
    </section>
  )
}

export default Contact
